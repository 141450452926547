import React from "react";
import { Button, Form, Input, Row, Col, notification } from "antd";
import classNames from "classnames";
import axios from 'axios';
const crypto = require('crypto');

class ResetPwd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passBarShow: false,
      rePassBarShow: false,
      passStrength: "L",
      rePassStrength: "L",
    };
  }
  sha256Format(str) {
    const hash = crypto.createHash('sha256');
    hash.update(str);
    return hash.digest('hex');
  }
  handleSubmit = (e) => {
    e.preventDefault();
    const urlParams = new URLSearchParams(window.location.href.split('?')[1]);
    const token = urlParams.get('token');
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let formatValues = {};
        for (const item in values) {
          if (Object.hasOwnProperty.call(values, item)) {
            formatValues[item] = this.sha256Format(values[item]);
          }
        }
        let params = {
          ...formatValues,
          token: token
        }
        axios.post('/starpower/api/account/resetPwd', params).then((res) => {
          if (res.data.code && res.data.code !== 200) {
            notification.error({
              top: 24,
              duration: 1.5,
              placement: 'topRight',
              message: 'Error',
              description: res.data.msg,
              onClick: () => {
                console.log('close');
              },
            })
          } else {
            notification.success({
              top: 70,
              duration: 1.5,
              placement: 'topLeft',
              message: 'Success',
              description:
                'Submit Success',
              onClick: () => {
                console.log('close');
              },
            })
          }
        }).catch((error) => {
          notification.error({
            top: 24,
            duration: 1.5,
            placement: 'topLeft',
            message: 'Error',
            description: error,
            onClick: () => {
              console.log('close');
            },
          })
        });
      }
    });
  };

  handleReset = (e) => {
    e.preventDefault();
    this.props.form.resetFields();
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    const regEx = /^.*(?=.{8,})(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*?(),.":{}|<>]).*$/;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      if (regEx.test(value)) {
        callback();
      } else {
        callback("Password must contain at least 8 characters, including uppercase letter, lowercase letter, numbers, and special character !");
      }
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirmPassword"], { force: true });
    }
    callback();
  };

  renderPassStrengthBar(type) {
    const strength =
      type === "pass" ? this.state.passStrength : this.state.rePassStrength;
    const classSet = classNames({
      "ant-pwd-strength": true,
      "ant-pwd-strength-low": strength === "L",
      "ant-pwd-strength-medium": strength === "M",
      "ant-pwd-strength-high": strength === "H",
    });
    const level = {
      L: "Low",
      M: "Middle",
      H: "High",
    };

    return (
      <div>
        <ul className={classSet}>
          <li className="ant-pwd-strength-item ant-pwd-strength-item-1"></li>
          <li className="ant-pwd-strength-item ant-pwd-strength-item-2"></li>
          <li className="ant-pwd-strength-item ant-pwd-strength-item-3"></li>
          <span className="ant-form-text">{level[strength]}</span>
        </ul>
      </div>
    );
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <Row>
            <Col>
              <Form.Item label="Password" hasFeedback>
                {getFieldDecorator("password", {
                  rules: [
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      validator: this.validateToNextPassword,
                    },
                  ],
                  onchange,
                })(<Input.Password />)}
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Item label="Confirm Password" hasFeedback>
                {getFieldDecorator("confirmPassword", {
                  rules: [
                    {
                      required: true,
                      message: "Please confirm your password!",
                    },
                    {
                      validator: this.compareToFirstPassword,
                    },
                  ],
                })(<Input.Password onBlur={this.handleConfirmBlur} />)}
              </Form.Item>
            </Col>
          </Row>
          <Row className="confirm-btn">
            <Col span={12}>
              <Button className="submit-btn" htmlType="submit">
                Submit
              </Button>
            </Col>
            <Col span={12}>
              <Button onClick={this.handleReset}>Reset</Button>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}
const ResetPwdForm = Form.create()(ResetPwd);
export default ResetPwdForm;
