import React from "react";


export const itemList = [
  {
    title:
      "Starplug",
    desc: (
      <>
        Plug and Play<br />
        Earn Passive Income & Save the Earth
      </>
    ),
    price: "$109",
    btn: {
      text: "Buy Now",
      className: "product-btn-primary",
    },
    imgSrc: require("@/assets/images/product/starplug.jpg"),
    href: "https://starpower-market.myshopify.com/products/starplug",
  },
  {
    title:
      "Starbattery",
    desc: (
      <>
        First Web3-enabled battery<br />
        Power Your Home, Save Money
      </>
    ),
    price: "",
    btn: {
      text: "Pre Order",
      className: "",
    },
    imgSrc: require("@/assets/images/product/starbattery.jpg"),
    href: "https://starpower-market.myshopify.com/products/starbattery",
  },
  {
    title:
      "Starcharger",
    desc: (
      <>
        Charge Ahead with DePIN-Powered<br />
        Energy by Starcharger
      </>
    ),
    price: "",
    btn: {
      text: "Coming Soon",
      className: "product-btn-disabled",
    },
    imgSrc: require("@/assets/images/product/starcharger.jpg"),
    href: "",
  },
];