import React, { Component } from "react";
import PropTypes from "prop-types";
import { enquireScreen } from "enquire-js";
import { itemList } from "./data.source";
import "./less/product.less";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});

let myVideo1;

class Product extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPlayed: 0,
    };
  }

  componentDidMount() {
    // myVideo1 = document.getElementById("jzVideo1");
    // myVideo1.addEventListener(
    //   "ended",
    //   function () {
    //     myVideo1.currentTime = 0.1;
    //     myVideo1.play();
    //     this.state = {
    //       isPlayed: 0,
    //     };
    //   },
    //   false
    // );
  }

  componentWillUnmount() {}

  // onPlayClick = () => {
  //   myVideo1.play();
  //   this.setState({
  //     isPlayed: this.state.isPlayed + 1,
  //   });
  // };

  onLinkClick = (href,isRedirect) => {
    if (!href) return;
    if(isRedirect){
      window.location.href=href
    }else{
      window.open(href, "_blank");
    }
  }
  clientWidth = () => {
    return (window.innerWidth || document.documentElement.clientWidth)
  }
  videoWidth = () => {
    return this.clientWidth() >= 1440 ? 1392 : this.clientWidth() - 80
  }
  videoHeight = () => {
    return this.videoWidth() / 1.75
  }
  clientHeight = () => {
    return (window.screen.height || document.documentElement.clientHeight)
  }

  render() {
    let { isPlayed } = this.state;
    return (
      <div className="product-page">
        <div className="product-sub-title">LET'S GET STARTED</div>
        <div className="product-title">Connect device and earn crypto.</div>
        <div className="product-item-wrapper">
          {itemList.map(({ title, desc, price, btn, imgSrc, href, isRedirect }) => (
            <div className="product-item">
              <img src={imgSrc} alt="" />
              <div className="product-name">{title}</div>
              <div className="product-desc">{desc}</div>
              <div className="product-price">{price}</div>
              <div className={`product-btn ${btn.className}`} onClick={() => this.onLinkClick(href,isRedirect)}>{btn.text}</div>
            </div>
          ))}
        </div>
        <div className="video-container">
          <iframe
            // width="1392"
            // height="794"
            width={this.videoWidth()}
            height={this.videoHeight()}
            className="product-video-src"
            src="https://www.youtube.com/embed/cU-bUA7ppbM?si=c0pyOBeRMt3_CayC"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
          ></iframe>
          {/* <video
            className="floor-video-src"
            id="jzVideo1"
            muted
            playsInline
            x5-video-player-type="h5"
            src="https://www.youtube.com/embed/cU-bUA7ppbM?si=c0pyOBeRMt3_CayC"
            poster={require("../assets/images/product/earning.png")}
          />
          {isPlayed === 0 && (
            <div className="play-btn">
              <div className="border" onClick={this.onPlayClick}></div>
            </div>
          )} */}
        </div>
      </div>
    );
  }
}

Product.propTypes = {};

export default Product;
