import React from "react";
import { Button, Form, Input, Row, Col, notification, Icon } from "antd";
import classNames from "classnames";
import axios from 'axios';

class NFTapply extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passBarShow: false,
      rePassBarShow: false,
      passStrength: "L",
      rePassStrength: "L",
    };
  }
  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFieldsAndScroll((err, values) => {
      if (!err) {
        let params = {
          ...values
        }
        axios.post('/starpower/manage/api/submitNftApply', params).then((res) => {
          if (res.data.code && res.data.code !== 200) {
            notification.error({
              top: 100,
              duration: 1.5,
              placement: 'topRight',
              message: 'Error',
              description: res.data.msg,
              onClick: () => {
                console.log('close');
              },
            })
          } else {
            notification.success({
              top: 100,
              duration: 1.5,
              placement: 'topLeft',
              message: 'Success',
              description:
                'Submit Success',
              onClick: () => {
                console.log('close');
              },
            })
          }
        }).catch((error) => {
          notification.error({
            top: 100,
            duration: 1.5,
            placement: 'topLeft',
            message: 'Error',
            description: error,
            onClick: () => {
              console.log('close');
            },
          })
        });
      }
    });
  };

  handleReset = (e) => {
    e.preventDefault();
    this.props.form.resetFields();
    this.props.closeNFTfunc(false);
  };

  handleConfirmBlur = (e) => {
    const { value } = e.target;
    this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  };
  compareToFirstPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && value !== form.getFieldValue("password")) {
      callback("Two passwords that you enter is inconsistent!");
    } else {
      callback();
    }
  };

  validateToNextPassword = (rule, value, callback) => {
    const { form } = this.props;
    if (value && this.state.confirmDirty) {
      form.validateFields(["confirmPassword"], { force: true });
    }
    callback();
  };

  renderPassStrengthBar(type) {
    const strength =
      type === "pass" ? this.state.passStrength : this.state.rePassStrength;
    const classSet = classNames({
      "ant-pwd-strength": true,
      "ant-pwd-strength-low": strength === "L",
      "ant-pwd-strength-medium": strength === "M",
      "ant-pwd-strength-high": strength === "H",
    });
    const level = {
      L: "Low",
      M: "Middle",
      H: "High",
    };

    return (
      <div>
        <ul className={classSet}>
          <li className="ant-pwd-strength-item ant-pwd-strength-item-1"></li>
          <li className="ant-pwd-strength-item ant-pwd-strength-item-2"></li>
          <li className="ant-pwd-strength-item ant-pwd-strength-item-3"></li>
          <span className="ant-form-text">{level[strength]}</span>
        </ul>
      </div>
    );
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <div className="NFT-modal-content">
        <div className="NFT-info">
        </div>
        <Form onSubmit={this.handleSubmit} className="NFT-apply">
            <Row>
              <Col>
                <Form.Item label="Pioneer Pass NFT Wallet Address" hasFeedback>
                  <a className="help" href="https://starpower.gitbook.io/https-starpower.gitbook.io-documents/pioneer-pass-nft/nft-activation" target="_blank">Help？</a>
                  {getFieldDecorator("walletAddress", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your Wallet Address!",
                      },
                    ],
                    onchange,
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="Starpower dAPP account Email" hasFeedback>
                  {getFieldDecorator("email", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your email!",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Item label="NFT Token Address on Solscan" hasFeedback>
                  {getFieldDecorator("nftAddress", {
                    rules: [
                      {
                        required: true,
                        message: "Please input your NFT Token Address on Solscan!",
                      },
                    ],
                  })(<Input />)}
                </Form.Item>
              </Col>
            </Row>
            <div className="NFT-footer">Please transfer the Pioneer Pass  NFT to the following official wallet address within 6 hours after submission:</div>
          <div className="NFT-keys">
            SpJaMk8cvNz5MJEsFXU5GVs2zsFaFXCGmi5WwaUwsRD
          </div>
            <Row className="confirm-btn">
              <Col span={12}>
                <button onClick={this.handleReset} className='cancel-btn'>Cancel</button>
              </Col>
              <Col span={12}>
                <button className="submit-btn">
                  Submit
                </button>
              </Col>
            </Row>
          </Form>
      </div>
    );
  }
}
const NFTapplyForm = Form.create()(NFTapply);
export default NFTapplyForm;
