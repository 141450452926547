/*
 * @Description:
 * @Version: 1.0
 * @Autor: StevenWu
 * @Date: 2024-04-30 14:35:37
 * @LastEditors: StevenWu
 * @LastEditTime: 2024-05-05 02:11:44
 */
import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./App"
import * as serviceWorker from "./serviceWorker"

// 监听屏幕宽度变化
// window.addEventListener("resize", () => {
//   window.opener.location.reload()
// })

ReactDOM.render(<App />, document.getElementById("root"))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
