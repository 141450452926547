/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import "./less/antMotionStyle.less";
import { Button, notification, Icon, Pagination } from "antd";
import axios from "axios";
import { PublicKey } from "@solana/web3.js";

let isMobile;
enquireScreen((b) => {
  isMobile = b;
});
const { location = {} } = typeof window !== "undefined" ? window : {};

const validateSolAddress = (address) => {
  try {
    let pubkey = new PublicKey(address);
    let isSolana = PublicKey.isOnCurve(pubkey.toBuffer());
    return isSolana;
  } catch (error) {
    return false;
  }
};

const addrSubStr = (strParam) => {
  return (
    strParam.slice(0, 6) +
    "..." +
    strParam.slice(strParam.length - 5, strParam.length - 1)
  );
};

const getUrlParams = (paramName) => {
  const url = new URL(window.location.href);
  return url.searchParams.get(paramName);
};

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
      show: !location.port,
      drawerVisible: true,
      dashboardData: "",
      step: 1,
      pageNum: 1,
      solAddress: "",
      formatSolAddr: "",
      notConnectWallet: true,
      registInfo: {
        inviteCode: "",
      },
      estimatedRank: 0,
      invitedListInfo: {},
      refCode: "",
      registed: false,
      canReg: false,
      btnLoading: false,
      checkPass: false,
      DevicesCount: 0,
      NFTCount: 0,
      countdown: 0,
      postState: true,
    };
  }

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
    document.body.style.overflow = 'auto';
    const solAddr = localStorage.getItem('solanaAddress') || '';
    const solRegAddr = localStorage.getItem("solanaRegAddress") || "";
    let codeStr = getUrlParams("ReferralCode");
    if (codeStr && codeStr.length > 0) {
      this.setState({
        refCode: codeStr,
      });
    } else {
      this.setState({
        refCode: "",
      });
    }
    if (solAddr && solAddr.length > 0) {
      this.getNFTCount();
      let deviceInfo = JSON.parse(localStorage.getItem('deviceInfo')) || [];
      for (let i = 0; i < deviceInfo.length; i++) {
        if (deviceInfo[i].sol == solAddr) {
          console.log(deviceInfo[i]);
          this.setState({
            DevicesCount: deviceInfo[i].value
          })
        }
      }
    }
    if (solRegAddr && solRegAddr.length > 0) {
      this.setState({
        step: 3,
        solAddress: solRegAddr,
      });
      this.getEstimatedRank(solRegAddr);
      this.getInvitedList();
      this.getRefInfo();
    } else {
      this.setState({
        step: 1,
        solAddress: solRegAddr,
      });
    }
    window.scrollTo(0, 0);
  }
  checkAddr = async () => {
    let addr = document.getElementById("walletAddress").value;
    if (addr && addr.length > 0) {
      this.setState({
        registed: false,
        canReg: false,
      });
    }
    let isSolAddress = validateSolAddress(addr);
    if (isSolAddress) {
      this.setState({
        btnLoading: true,
      });
      axios({
        url: "/refer/api/checkIfExist?solanaAddress=" + addr,
        method: "get",
        headers: {
          "Content-Type": "multipart/form-data", //Content-Type form
          //'Content-Type': 'application/json'
        },
      }).then((res) => {
        localStorage.setItem("solanaRegAddress", addr);
        localStorage.setItem("notConnectWallet", true);
        if (res.data.data) {
          this.setState({
            registed: true,
            canReg: true,
            checkPass: true,
          });
        } else {
          this.setState({
            registed: false,
            canReg: true,
            checkPass: false,
          });
        }
        this.setState({
          btnLoading: false,
        });
        console.log(res.data.data);
      });
    }
  };
  getRefInfo = async () => {
    let addr =
      this.state.solAddress ||
      localStorage.getItem("solanaRegAddress") ||
      localStorage.getItem("solanaAddress");
    await axios.get("/refer/api/referInfo?solAddress=" + addr).then((res) => {
      if (res.data.code == 200) {
        this.setState({
          registInfo: res.data.data,
        });
        if (this.state.refCode != this.state.registInfo.inviteCode) {
          if (document.getElementById("refCode")) {
            document.getElementById("refCode").value = this.state.refCode;
          }
        }
      }
    });
  };
  registWallet = async () => {
    let addr = document.getElementById("walletAddress").value;
    let isSolAddress = validateSolAddress(addr);
    if (isSolAddress) {
      axios({
        url: "/refer/api/register?solanaAddress=" + addr,
        method: "get",
        headers: {
          // "Content-Type": "multipart/form-data", //Content-Type form
          'Content-Type': 'application/json'
        },
      })
        .then((res) => {
          if (res.data.code == 200) {
            localStorage.setItem("solanaRegAddress", addr);
            localStorage.setItem("notConnectWallet", true);
            this.setState({
              step: 2,
              notConnectWallet: true,
              solAddress: addr,
            });
            this.getRefInfo();
            this.getEstimatedRank(addr);
          } else if (res.data.code == 20202) {
            localStorage.setItem("solanaRegAddress", addr);
            notification.success({
              top: 70,
              duration: 1.5,
              placement: "bottomLeft",
              message: "Succress",
              description: res.data.msg,
              onClick: () => {
                console.log("close");
              },
            });
            this.setState({
              step: 1,
              registed: true,
              solAddress: addr,
            });
          } else {
            notification.error({
              top: 70,
              duration: 1.5,
              placement: "bottomLeft",
              message: "Error",
              description: res.data.msg,
              onClick: () => {
                console.log("close");
              },
            });
          }
          // this.getInvitedList();
        })
        .catch((error) => {
          notification.error({
            top: 70,
            duration: 2,
            placement: "bottomLeft",
            message: "Error",
            description: error.message,
            onClick: () => {
              console.log("close");
            },
          });
        });
    } else {
      notification.error({
        top: 70,
        duration: 1.5,
        placement: "bottomLeft",
        message: "Error",
        description: "Please enter the correct solana wallet address",
        onClick: () => {
          console.log("close");
        },
      });
      console.log("The address is NOT valid");
    }
  };
  goToProfile = () => {
    if (localStorage.getItem("solanaRegAddress")) {
      window.location.reload();
    } else {
      let addr = document.getElementById("walletAddress").value;
      localStorage.setItem("solanaRegAddress", addr);
      window.location.reload();
    }
  };
  getEstimatedRank = async (addr) => {
    let params = {
      solAddress: addr,
    };
    await axios({
      url: "/refer/api/estimatedRank",
      method: "get",
      params: params,
      headers: {
        "Content-Type": "multipart/form-data", //Content-Type form
        // "Content-Type": "application/json",
      },
    })
      .then((res) => {
        this.setState({
          estimatedRank: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // get invited list
  getInvitedList = async (pageNum) => {
    let params = {
      solanaAddress:
        this.state.solAddress ||
        localStorage.getItem("solanaRegAddress") ||
        localStorage.getItem("solanaAddress"),
      pageNum: pageNum ? pageNum : this.state.pageNum,
      pageSize: 10,
    };
    await axios({
      url: "/refer/api//listInvited",
      method: "get",
      params: params,
      headers: {
        "Content-Type": "multipart/form-data", //Content-Type form
        // "Content-Type": "application/json",
      },
    })
      .then((res) => {
        this.setState({
          invitedListInfo: res.data.data,
          step: 3,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // copy referral code
  copyRefCode() {
    let refCode = document.getElementById("myRefCode").value;
    const textarea = document.createElement("textarea");
    if (window.location.href.indexOf("?") > 0) {
      textarea.value =
        window.location.href.slice(0, window.location.href.indexOf("?")) +
        "?ReferralCode=" +
        refCode;
    } else {
      textarea.value =
        window.location.href.slice(0, window.location.href.length) +
        "?ReferralCode=" +
        refCode;
    }
    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    notification.success({
      top: 70,
      duration: 1.5,
      placement: "bottomLeft",
      message: "Succress",
      description: "copy Succress",
      onClick: () => {
        console.log("close");
      },
    });
  }
  // Input to UpperCase
  handleInput = (event) => {
    const value = event.target.value;
    const filteredValue = value.replace(/[^a-zA-Z]/g, "");
    event.target.value = filteredValue.toUpperCase();
  };
  // Submit Referral code
  submitReferral = async () => {
    let refCode = document.getElementById("refCode").value;
    localStorage.setItem('submitRef', true);
    localStorage.setItem('refCode', refCode);
    localStorage.setItem('walletVisible', true)
  };
  changePage = (pageNum) => {
    this.getInvitedList(pageNum);
    this.setState({
      pageNum: pageNum,
    });
  };
  getDevicesCount = async () => {
    const solAddr = localStorage.getItem("solanaAddress");
    const params = {
      solanaAddress: solAddr
    }
    if (this.state.postState == false) {
      if (this.state.countdown > 0 && this.state.countdown < 30) {
        return;
      }
      return;
    }
    this.setState({
      postState: false
    })
    await axios({
      url: "/refer/api/getDevicesCount",
      method: "get",
      params: params,
      headers: {
        "Content-Type": "multipart/form-data", //Content-Type form
        // "Content-Type": "application/json",
      },
    })
      .then((res) => {
        this.setState({
          DevicesCount: res.data.data,
          countdown: 0
        });
        this.countdownFn();
        let deviceInfo = JSON.parse(localStorage.getItem('deviceInfo')) || [];
        for (let i = 0; i < deviceInfo.length; i++) {
          if (deviceInfo[i].sol == solAddr) {
            deviceInfo[i].value = res.data.data
            localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
            return;
          }
        }
        deviceInfo.push({
          sol: solAddr,
          value: res.data.data
        });
        localStorage.setItem('deviceInfo', JSON.stringify(deviceInfo));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  getNFTCount = async () => {
    let params = {
      solanaAddress: localStorage.getItem("solanaAddress")
    }
    await axios({
      url: "/refer/api/getNFTCount",
      method: "get",
      params: params,
      headers: {
        "Content-Type": "multipart/form-data", //Content-Type form
        // "Content-Type": "application/json",
      },
    })
      .then((res) => {
        console.log(res);
        
        this.setState({
          NFTCount: res.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  countdownFn() {
    this.timer = setInterval(() => {
      if (this.state.countdown === 30) {
        this.setState({
          postState: true
        })
        clearInterval(this.timer);
      } else {
        this.setState(prevState => ({
          countdown: prevState.countdown + 1,
          postState: false
        }));
      }
    }, 1000);
  }
  render() {
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        {this.state.step == 1 && (
          <div className="wallet-container1">
            <div className="wallet-title">
              <p>User On-boarding Airdrop</p>
              <p>Share 30,000,000 STARs</p>
            </div>
            <div className="wallet-addrInput">
              <input
                id="walletAddress"
                className="wallet-addrInput-input"
                type="text"
                onChange={this.checkAddr}
                placeholder="Enter your wallet address"
              />
              {this.state.registed ? (
                <Button
                  className="wallet-addrInput-submit registed"
                  type="primary"
                  loading={this.state.btnLoading}
                  onClick={this.goToProfile}
                >
                  Go to profile
                </Button>
              ) : (
                <Button
                  className={
                    this.state.canReg
                      ? "wallet-addrInput-submit registed"
                      : "wallet-addrInput-submit"
                  }
                  type="primary"
                  loading={this.state.btnLoading}
                  onClick={this.registWallet}
                >
                  Register
                </Button>
              )}
            </div>
            {this.state.checkPass && (
              <div className="check-pass">
                Solana address is already registered.
              </div>
            )}
            <div className="wallet-message">
              <p>
                Connect your wallet and use referral code to boost your rank to
                get more rewards.
              </p>
            </div>
          </div>
        )}
        {this.state.step == 2 && (
          <div className="wallet-container2">
            <div className="wallet-card1">
              <div className="wallet-card1-title">REGISTER SUCCEED</div>
              <div className="wallet-card1-info">
                <p className="card1-info-title">Your Rank Is</p>
                <p className="card1-info-rank">
                  #{this.state.registInfo ? this.state.registInfo.rank : "-"}
                </p>
                <p className="card1-info-estimated">
                  Estimated Rank After Verification: #
                  {this.state.estimatedRank ? this.state.estimatedRank : "-"}
                </p>
                <p className="card1-info-message">
                  Verifying your wallet ownership can boost your ranking,
                  (Simply sign, no need for transactions)
                </p>
              </div>
              <div className="wallet-card1-option">
                <Button
                  className="card1-option-btn"
                  type="primary"
                  onClick={()=>localStorage.setItem('walletVisible', true)}
                >
                  Connect Wallet
                </Button>
                <Button
                  className="card1-option-skip"
                  type="primary"
                  onClick={this.goToProfile}
                >
                  Skip
                </Button>
              </div>
            </div>
          </div>
        )}
        {this.state.step == 3 && (
          <div className="wallet-container3">
            <div className="wallet-card2-top">
              <div className="card2-top-base">
                <span>
                  {this.state.registInfo
                    ? this.state.registInfo.solAddress &&
                      this.state.registInfo.solAddress.length > 20
                      ? addrSubStr(this.state.registInfo.solAddress)
                      : this.state.registInfo.solAddress
                    : "-"}
                </span>
                <span>Invited by</span>
                <span>
                  {this.state.registInfo
                    ? this.state.registInfo.invitedCode
                      ? this.state.registInfo.invitedCode +
                        " #" +
                        this.state.registInfo.rankRefer
                      : "-----"
                    : "------"}
                </span>
              </div>
              <div className="card2-top-info">
                <div className="card2-info-ranking">
                  <p>Ranking</p>
                  <p>
                    #{this.state.registInfo ? this.state.registInfo.rank : "-"}
                  </p>
                </div>
                <div className="card2-info-total">
                  <p>Total Referral</p>
                  <p>
                    {this.state.registInfo
                      ? this.state.registInfo.totalReferral
                      : "-"}
                  </p>
                </div>
              </div>
            </div>
            {localStorage.getItem("solanaAddress") && (
              <div className="wallet-card2-middle">
              <div className="card2-middle-title">Bonus Reward
                <a href="https://starpower.gitbook.io/https-starpower.gitbook.io-documentation" target="_blank">
                  <Icon className="middle-title-icon" type="question-circle" />
                </a>
              </div>
              <div className="card2-middle-info">
                <div className="card2-middle-card">
                  <div className="middle-card">
                    <p>Pioneer Pass<br/>Badge Holders</p>
                    <p>Activated*{this.state.NFTCount || 0}</p>
                    <p>
                      {(this.state.NFTCount > 0) ? (
                        <Icon className="middle-card-icon-right" type="check" />
                      ) : (
                        <Icon className="middle-card-icon-wrong" type="close-circle" />
                      )}
                      
                    </p>
                  </div>
                  <p className="card2-middle-Link">
                    <a href="https://starpower.gitbook.io/https-starpower.gitbook.io-documentation/pioneer-pass-nft/nft-activation" target="_blank">How to activate Pioneer NFT →</a>
                  </p>
                </div>
                <div className="card2-middle-card">
                  <div className="middle-card">
                    <p>Hardware<br/>Holders</p>
                    <p>Starplug*{this.state.DevicesCount || 0}</p>
                    <p>
                      <button className="card2-middle-btn" type="primary" onClick={this.getDevicesCount}>Verify</button>
                    </p>
                  </div>
                  <p className="card2-middle-Link">
                    <a href="https://starpower-market.myshopify.com/products/starplug" target="_blank">Buy one and connected now →</a>
                  </p>
                </div>
              </div>
            </div>
            )}
            {localStorage.getItem("solanaAddress") ? (
              <div className="wallet-card2-bottom">
                <div className="card2-bottom-title">
                  <span>Referrals</span>
                  {this.state.registInfo.invitedCode &&
                    ( <p className="card2-bottom-right-title">
                      Share Your Referral{" "}
                      <Icon
                        type="copy"
                        onClick={this.copyRefCode}
                        className="right-title-copy"
                        theme="filled"
                      />
                    </p>)
                  }
                </div>
                <div className="card2-bottom-info">
                  {!this.state.registInfo.invitedCode && (
                    <div className="card2-bottom-left">
                      <p className="card2-bottom-left-title">
                        Submit Referral Code
                      </p>
                      <p className="card2-bottom-left-input">
                        <input
                          readOnly={
                            this.state.refCode &&
                            this.state.refCode.length > 0 &&
                            this.state.refCode !=
                              this.state.registInfo.inviteCode
                              ? true
                              : false
                          }
                          id="refCode"
                          className="wallet-addrInput-input"
                          type="text"
                          maxLength="6"
                          onInput={this.handleInput}
                          placeholder="------"
                        />
                      </p>
                      <p className="card2-bottom-left-msg">
                        Enter the code to boost your rank.
                        <br />
                        *Once you submit the referral code, it can't be changed.
                      </p>
                      <p className="card2-bottom-left-option">
                        <button
                          className="card2-option-btn"
                          type="primary"
                          onClick={this.submitReferral}
                        >
                          submit
                        </button>
                      </p>
                    </div>
                  )}
                  <div
                    className={
                      !this.state.registInfo.invitedCode
                        ? "card2-bottom-right"
                        : "card2-bottom-center"
                    }
                  >
                    { !this.state.registInfo.invitedCode &&
                      ( <p className="card2-bottom-right-title">
                        Share Your Referral{" "}
                        <Icon
                          type="copy"
                          onClick={this.copyRefCode}
                          className="right-title-copy"
                          theme="filled"
                        />
                      </p>)
                    }
                    <p className="card2-bottom-right-input">
                      <input
                        readOnly
                        id="myRefCode"
                        className="wallet-addrInput-input"
                        type="text"
                        maxLength="6"
                        value={
                          this.state.registInfo
                            ? this.state.registInfo.inviteCode
                            : "------"
                        }
                      />
                    </p>
                    <div className="card2-bottom-right-list">
                      <p>
                        <span>Friends you invited</span>{" "}
                        <span>
                          {this.state.invitedListInfo
                            ? this.state.invitedListInfo.total
                            : "0"}
                        </span>
                      </p>
                      {this.state.invitedListInfo &&
                      this.state.invitedListInfo.data &&
                      this.state.invitedListInfo.data.length > 0 ? (
                        this.state.invitedListInfo.data.map((item) => {
                          return (
                            <p key={item.solanaAddress}>
                              <span>{item.solanaAddress}</span>
                              <span>#{item.rank}</span>
                            </p>
                          );
                        })
                      ) : (
                        <p>
                          <span>----------</span>
                          <span>#-----</span>
                        </p>
                      )}
                      {this.state.invitedListInfo &&
                      this.state.invitedListInfo.total &&
                      this.state.invitedListInfo.total > 10 ? (
                        <Pagination
                          class="list-pagination"
                          defaultCurrent={1}
                          total={this.state.invitedListInfo.total}
                          onChange={this.changePage}
                        />
                      ) : (
                        ""
                      )}
                    </div>
                    {this.state.invitedListInfo &&
                    this.state.invitedListInfo.total &&
                    this.state.invitedListInfo.total > 0 ? (
                      ""
                    ) : (
                      <p className="card2-bottom-right-msg">
                        Copy your code and share the link with your friends over
                        twitter or any other social platform.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <div className="wallet-card2-bottom">
                <div className="card2-bottom-verify">
                  <p>Verify your wallet to boost your rank!</p>
                  <div className="bottom-verify-option">
                    <button
                      className="bottom-verify-btn"
                      type="primary"
                      onClick={()=>localStorage.setItem('walletVisible', true)}
                    >
                      Connect Wallet
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}
