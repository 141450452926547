/* eslint no-undef: 0 */
/* eslint arrow-parens: 0 */
import React from "react";
import { enquireScreen } from "enquire-js";
import "./less/resetPwdStyle.less";
import ResetPwdForm from './resetPwd'


let isMobile;
enquireScreen((b) => {
  isMobile = b;
});


const { location = {} } = typeof window !== "undefined" ? window : {};
export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile,
    };
  }

  componentDidMount() {
    enquireScreen((b) => {
      this.setState({ isMobile: !!b });
    });
  }
  render() {
    return (
      <div
        className="templates-wrapper"
        ref={(d) => {
          this.dom = d;
        }}
      >
        <div className="reset-pwd">
          <div className="reset-body">
            <ResetPwdForm />
          </div>
        </div>
      </div>
    );
  }
}
