import React from 'react';
import logoIcon_black from "../assets/images/logo-new2.png";
import sidebarIcon from "../assets/images/sidebar-icon.png";
import { Icon } from "antd";

export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper lvu02jzfxlb-editor_css' },
  page: { className: 'home-page' },
  logo: {
    className: "header0-logo",
    href: "/",
    children: logoIcon_black,
  },
  Menu: {
    className: 'header0-menu lvu054404cb-editor_css',
    children: [
      {
        name: 'item1',
        className:
          'header0-item ant-menu-submenu ant-menu-submenu-horizontal lvu022uivs-editor_css',
        children: {
          href: '/product',
          isRedirect:true,
          children: [
            {
              children: (
                <span>
                  <p>Product</p>
                </span>
              ),
              name: 'text',
              className: 'lvu0aa9gxvr-editor_css',
            },
          ],
        },
        // subItem: [
        //   {
        //     className: 'item-sub ant-menu-item lvtzvlvhhap-editor_css',
        //     children: {
        //       className: 'item-sub-item lvtzx1fse4-editor_css',
        //       children: [
        //         {
        //           name: 'title~lvtzybmxwoe',
        //           className: 'lvtzynsln4s-editor_css',
        //           href: "/starplug",
        //           children: (
        //             <span>
        //               <p><span>Starplug</span></p>
        //             </span>
        //           ),
        //         },
        //         {
        //           name: 'title~lvtzyi0i4l6',
        //           className: 'lvtzynsln4s-editor_css',
        //           href: "/starbattery",
        //           children: (
        //             <span>
        //               <p><span>Starbattery</span></p>
        //             </span>
        //           ),
        //         },
        //         {
        //           name: 'title~lvtzyi0i4l6',
        //           className: 'lvtzynsln4s-editor_css',
        //           href: "/ev-charging",
        //           children: (
        //             <span>
        //               <p><span>Starcharger</span></p>
        //             </span>
        //           ),
        //         },
        //       ],
        //     },
        //   },
        // ],
      },
      {
        name: 'item2',
        className: 'header0-item lvu09ws29k-editor_css',
        children: {
          href: 'https://medium.com/@starpowerworld',
          target: "_blank",
          children: [
            {
              children: (
                <span>
                  <p>Blog</p>
                </span>
              ),
              name: 'text',
              className: 'lvu08ulsiw-editor_css',
            },
          ],
        },
      },
      {
        name: 'item3',
        className:
          'header0-item ant-menu-submenu ant-menu-submenu-horizontal lvu022uivs-editor_css',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>Community</p>
                </span>
              ),
              name: 'text',
              className: 'lvu0aa9gxvr-editor_css',
            },
          ],
        },
        subItem: [
          {
            className: 'item-sub ant-menu-item lvtzvlvhhap-editor_css',
            children: {
              className: 'item-sub-item lvtzx1fse4-editor_css',
              children: [
                {
                  name: 'title~lvtzybmxwoe',
                  className: 'lvtzynsln4s-editor_css',
                  href: "https://discord.gg/starpowernetwork",
                  target: "_blank",
                  children: (
                    <span>
                      <p><span>Discord</span></p>
                    </span>
                  ),
                },
                {
                  name: 'title~lvtzyi0i4l6',
                  className: 'lvtzynsln4s-editor_css',
                  href: "https://t.me/starpowernetwork",
                  target: "_blank",
                  children: (
                    <span>
                      <p><span>Telegram</span></p>
                    </span>
                  ),
                },
                {
                  name: 'title~lvtzyi0i4l6',
                  className: 'lvtzynsln4s-editor_css',
                  href: "https://x.com/starpowerworld",
                  target: "_blank",
                  children: (
                    <span>
                      <p><span>X (Twitter)</span></p>
                    </span>
                  ),
                },
              ],
            },
          },
        ],
      },
      {
        name: 'item4',
        className:
          'header0-item ant-menu-submenu ant-menu-submenu-horizontal lvu022uivs-editor_css',
        children: {
          href: '#',
          children: [
            {
              children: (
                <span>
                  <p>Mobile App</p>
                </span>
              ),
              name: 'text',
              className: 'lvu0aa9gxvr-editor_css',
            },
          ],
        },
        subItem: [
          {
            className: 'item-sub ant-menu-item lvtzvlvhhap-editor_css',
            children: {
              className: 'item-sub-item lvtzx1fse4-editor_css',
              children: [ 
                {
                  name: 'title~lvtzyi0i4l6',
                  className: 'lvtzynsln4s-editor_css',
                  href: "https://apps.apple.com/us/app/starpower-network/id6473820984",
                  target: "_blank",
                  children: (
                    <span>
                      <p><span>Apple Store</span></p>
                    </span>
                  ),
                },
                {
                  name: 'title~lvtzyi0i4l6',
                  className: 'lvtzynsln4s-editor_css',
                  href: "https://galaxystore.samsung.com/detail/com.starpower.starpower",
                  target: "_blank",
                  children: (
                    <span>
                      <p><span>Galaxy Store</span></p>
                    </span>
                  ),
                },
                {
                  name: 'title~lvtzybmxwoe',
                  className: 'lvtzynsln4s-editor_css',
                  href: "https://www.starpower.world/static/download/starpower.apk",
                  target: "_blank",
                  children: (
                    <span>
                      <p><span>Android Download</span></p>
                    </span>
                  ),
                },
              ],
            },
          },
        ],
      },
    ],
  },
  mobileMenu: { className: "header0-mobile-menu" },
  sidebar: {
    className: "sidebarIcon",
    children: sidebarIcon,
  },
};

